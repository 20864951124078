import { chunk } from 'lodash-es'
import proxyConfig from 'Config/proxy_config.json'

export const formatBytes = (bytes: number | unknown, decimals = 2) => {
  if (!(typeof bytes === 'number' && isFinite(bytes))) return 'Unknown'
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  if (i < 0) {
    i = 0
  }
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getPowNumber = (bytes: number) => {
  if (bytes === 0) return 0
  const k = 1024
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  if (i < 0) {
    i = 0
  }
  return Math.min(i, 4)
}

export const formatBytesByPow = (bytes: number, pow: number, decimals = 2) => {
  const dm = decimals < 0 ? 0 : decimals
  const k = 1024
  return parseFloat((bytes / Math.pow(k, pow)).toFixed(dm))
}

export const numberFormatter = (num: number | unknown, digits?: number) => {
  if (!(typeof num === 'number' && isFinite(num))) return 'NaN'
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
        ' ' +
        si[i].symbol
      )
    }
  }
  return num.toFixed(digits).toString()
}

export const downloadCSV = (data: string[][], filename: string) => {
  const csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n')
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename + '.csv')
  document.body.appendChild(link)
  link.click()
}

export async function chunckFn(
  func: (item: any) => void | Promise<void>,
  list: any[],
  chunckSize = 5
) {
  const chuncks = chunk(list, chunckSize)
  for (const ck of chuncks) {
    await Promise.allSettled(
      ck.map(c => {
        return func(c)
      })
    )
  }
}

export const getEnv = () => {
  if (proxyConfig.proxyLocation.includes('test')) {
    return 'test'
  }
  if (proxyConfig.proxyLocation.includes('stg')) {
    return 'staging'
  }
  return 'production'
}

export const removeTrailingSlash = (url: string) => {
  if (url.endsWith('/')) {
    return url.slice(0, -1)
  }
  return url
}

export const hash = (str: string): string => {
  let h = 0
  for (let i = 0; i < str.length; i++) {
    h = (h << 5) - h + str.charCodeAt(i)
    h = h & h
  }
  return Math.abs(h).toString(16)
}

export const randomString = (length: number): string => {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length)
}
